@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
.App {
  text-align: center;
  font-family: 'Roboto', sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/*@media (prefers-reduced-motion: no-preference) {*/
/*  .App-logo {*/
/*    animation: App-logo-spin infinite 20s linear;*/
/*  }*/
/*}*/

.header {
  z-index: 1;
  font-size: 2em;
  text-align: center;
  color: #fff;
  position: absolute;
  width: 80%;
  top: 50%;
  left: 50%;
  margin: 0 auto;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%)
}

#tsparticles {
  background: #222222;
  /* fallback for old browsers */
  /* background: -webkit-linear-gradient(to right, #2B32B2, #1488CC); */
  background: -webkit-linear-gradient(0deg, #222222 0%, #226666 80%, #50cccc 100%);
  /* Chrome 10-25, Safari 5.1-6 */
  /* background: linear-gradient(to right, #2B32B2, #1488CC); */
  background: linear-gradient(0deg, #222222 0%, #226666 80%, #50cccc 100%);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  display: flex;
  vertical-align: bottom;
  width: 100%;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed
}


.App-link {
  color: #61dafb;
}

/*@keyframes App-logo-spin {*/
/*  from {*/
/*    transform: rotate(0deg);*/
/*  }*/
/*  to {*/
/*    transform: rotate(360deg);*/
/*  }*/
/*}*/

